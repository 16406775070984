<template>
    <div class="container">
        <div class="my-profile-wrap">
            <page-header
                :title="'이벤트, 프로모션 등록'"
                
                :subtitle="'다양한 이벤트, 프로모션에 참여하고\n더 많은 섭외의 기회와 수익을 늘리세요!'"
            />

            <div class="ui-border-line ui-h-1 ui-mt-2 ui-mb-2" style="height: 10px"></div>

            <template v-for="(row, key) in artistEventList">
                <div :key="key">
                    <label-button
                        :title="row.title"
                        titleSize="23px"
                        :titleWeight="500"
                        right-icon="next"
                        @click="() => $gotoWeb(row.url)"
                    />
                    <div class="ui-border-line ui-mt-2 ui-mb-2"></div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import LabelButton from '@/components/common/LabelButton'

export default {
    components: {
        PageHeader,
        LabelButton,
    },
    computed: {
        artistEventList() {
            return this.$store.getters?.validArtistEventList || []
        },
    },
    mounted() {
        this.$store.dispatch('getArtistEventList')
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

h4 {
    font-weight: 400;
    font-size: 2rem !important;
}
</style>
