var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "my-profile-wrap" },
      [
        _c("page-header", {
          attrs: {
            title: "이벤트, 프로모션 등록",
            subtitle:
              "다양한 이벤트, 프로모션에 참여하고\n더 많은 섭외의 기회와 수익을 늘리세요!"
          }
        }),
        _c("div", {
          staticClass: "ui-border-line ui-h-1 ui-mt-2 ui-mb-2",
          staticStyle: { height: "10px" }
        }),
        _vm._l(_vm.artistEventList, function(row, key) {
          return [
            _c(
              "div",
              { key: key },
              [
                _c("label-button", {
                  attrs: {
                    title: row.title,
                    titleSize: "23px",
                    titleWeight: 500,
                    "right-icon": "next"
                  },
                  on: {
                    click: function() {
                      return _vm.$gotoWeb(row.url)
                    }
                  }
                }),
                _c("div", { staticClass: "ui-border-line ui-mt-2 ui-mb-2" })
              ],
              1
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }